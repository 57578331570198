/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-1",
    "aws_appsync_graphqlEndpoint": "https://45kiqjjntvd7fnk2b5iwbws65q.appsync-api.ap-southeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-iwuouxzgqvcexivh4fsymix654",
    "aws_cognito_identity_pool_id": "ap-southeast-1:aea65dfc-b964-459d-af3d-faf2b18ce8b1",
    "aws_cognito_region": "ap-southeast-1",
    "aws_user_pools_id": "ap-southeast-1_w5FBIyE2e",
    "aws_user_pools_web_client_id": "90pfd06gtfg0p3pkla3b5eu3a",
    "oauth": {},
    "aws_mobile_analytics_app_id": "95918c57b8314999b7c4885f03be2686",
    "aws_mobile_analytics_app_region": "us-west-2"
};


export default awsmobile;
