import React from 'react';
import { Auth, PubSub, Amplify } from 'aws-amplify';
import { AWSIoTProvider } from '@aws-amplify/pubsub/lib/Providers';

export default function PubSubPage() {
  React.useEffect(() => {
    Auth.currentCredentials().then((value) => {
      console.log(value);
    });

    // Apply plugin with configuration
    Amplify.addPluggable(
      new AWSIoTProvider({
        aws_pubsub_region: 'ap-southeast-1',
        aws_pubsub_endpoint: 'wss://a2ez5s3qjmy69m-ats.iot.ap-southeast-1.amazonaws.com/mqtt',
      }),
    );

    PubSub.subscribe('AmplifyTopic').subscribe({
      next: (data) => console.log('Message received', data),
      error: (error) => console.error(error),
      close: () => console.log('Done'),
    });
  }, []);

  return (
    <div>
      <amplify-button
        className='button'
        onClick={async () => {
          const result = await PubSub.publish('AmplifyTopic', {
            msg: 'Hello to all subscribers!',
            extra: {
              userId: 1,
            },
          });
          console.log(result);
        }}
      >
        Publish
      </amplify-button>
    </div>
  );
}
