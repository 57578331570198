import React, { useEffect, useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { listProducts } from '../graphql/queries';

export default function ProductPage() {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    fetchProducts();
  }, []);

  async function fetchProducts() {
    try {
      const result = await API.graphql(graphqlOperation(listProducts));
      const items = result.data.listProducts.items;

      console.log(result);

      setProducts(items);
    } catch (err) {
      console.log('error fetching products');
    }
  }

  return (
    <div>
      <h1>Product page</h1>
      {products.map((item, index) => (
        <div key={item.id ? item.id : index} style={{ display: 'flex', padding: 6 }}>
          <strong style={{ flex: 1 }}>{item.name}</strong>
          <strong>{item.price}</strong>
          <div style={{ width: 32 }}></div>
          <i>{item.discount}</i>
        </div>
      ))}
    </div>
  );
}
