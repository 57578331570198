import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Amplify from 'aws-amplify';
import DefaultLayout from './layouts/DefaultLayout';

import awsExports from './aws-exports';
Amplify.configure(awsExports);

export default function App() {
  return (
    <div>
      <Router basename={process.env.PUBLIC_URL}>
        <Switch>
          {/* <Route exact path={routes.ACCOUNT_LOGIN} component={Public} /> */}
          <Route path='/*' component={DefaultLayout} />
        </Switch>
      </Router>
    </div>
  );
}
