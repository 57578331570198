import React from 'react';
import { Route } from 'react-router-dom';
import { withAuthenticator, AmplifySignOut } from '@aws-amplify/ui-react';
import HomePage from '../pages/HomePage';
import ProductPage from '../pages/ProductPage';
import PubSubPage from '../pages/PubSubPage';
import routes from '../constants/routes';

const DefaultLayout = () => {
  return (
    <div style={{ padding: 12 }}>
      <Route exact path={routes.HOME_PAGE} component={HomePage} />
      <Route exact path={routes.PRODUCT_PAGE} component={ProductPage} />
      <Route exact path={routes.PUBSUB_PAGE} component={PubSubPage} />

      <div style={{ height: 12 }}></div>
      <AmplifySignOut />
    </div>
  );
};

export default withAuthenticator(DefaultLayout);
